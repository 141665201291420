'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var domCache = require('dom-cache');
var throttle = require('throttle');

// Give each instance of this module a unique id.
var instanceId = 0;
// Store instance data privately so it can't be accessed/modified.
var instanceData = {};
var config = {
	target: 'body',
	eventName: 'device-state-changed'
};

var getState = function() {

	var computedStyle = window.getComputedStyle(instanceData[this.id].target, '::before');
	var state = computedStyle.getPropertyValue('content');

	// Strip any quotes due to firefox bug.
	return state.replace(/'|"/g, '');

};

var saveState = function(state) {

	instanceData[this.id].lastState = state || this.getState();

};

var addEventHandlers = function() {

	domCache.window.on('resize.state-detection-' + this.id, throttle(function() {

		var data = instanceData[this.id];
		var state = getState.call(this);
		var e = {};

		console.log( 'resize for id: ' + this.id );

		if (state !== data.lastState) {

			console.log( 'state change for id:', this.id, state );

			e.type = data.eventName;
			e.oldState = data.lastState;
			e.newState = state;

			saveState.call(this, state);

			domCache.triggerCustomEvent(e);

		}

	}, 25).bind(this));

};

var StateDetection = function(opts) {

	var data;

	// Allow instantiation without the new keyword.
	if (this instanceof StateDetection) {

		Object.defineProperty(this, 'id', {value: instanceId++});

		// Keep all instance data private, except for the 'id', which will
		// be the key to get the private data for a specific instance.

		data = instanceData[this.id] = {
			lastState: ''
		};

		$.extend(data, config, opts);

		data.target = document.querySelector(data.target);

		addEventHandlers.call(this);
		saveState.call(this);

	} else {

		return new StateDetection(opts);

	}

};

StateDetection.prototype.destroy = function() {

	domCache.window.off('resize.state-detection-' + this.id);
	delete instanceData[this.id];

};

StateDetection.prototype.getState = getState;

module.exports = StateDetection;
