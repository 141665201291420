'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom = {};

dom.window = $(window);
dom.document = $(document);
dom.html = $('html');
dom.head = $('head');
dom.body = $('body');

dom.triggerCustomEvent = function(e) {

	dom.document.triggerHandler(e);

};

module.exports = dom;
