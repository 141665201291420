'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var ScrollWatch = require('scrollwatch');
var debounce = require('lodash/function/debounce');
var defer = require('lodash/function/defer');
require('jquery-ajaxQueue');

var cacheFactory = require('cache-factory');

var lazyImage = require('lazy-image');
var focalPoint = require('focal-point');

var masonry = require('./masonry.js');

var dom;
var state;
var options;
var swInstance;

var getDefaults = function() {

	return {
		totalItems: 0,
		isMasonry: false,
		isTextHidden: false,
		gutterSize: 0,
		infiniteOffset: 500,
		infiniteUrl: '',
		watchOffset: 0,
		watchThrottle: 250,
		lazyDebounceWait: 500,
		lazyOffset: 100,
		// Proxy lazy-image options.
		customScroller: '',
		customScrollerEvent: ''
	};

};

var getMoreItems = cacheFactory(function(dfd, url) {

	state.infiniteRequestQueue.add(function() {

		return $.ajax({
			url: url
		})
		.done(function(response) {

			// Resolve the deferred with the server response.
			dfd.resolve(response);

		})
		.fail(dfd.reject);

	});

});

var isCustomScroller = function() {

	return !!options.customScroller;

};

var setupInitialState = function() {

	state = {};

	state.lastItemIndex = null;

	// Make sure infinite scroll requests are handled synchronously so the images
	// stay in order.
	state.infiniteRequestQueue = $.ajaxQueue();

	// Every time an infinite request is made, flag it so that we don't repeat the
	// same request while on the same page.
	state.infiniteRequestCache = {};

};

var setupDom = function() {

	dom = {};

	dom.grid = $('.image-grid');

};

// Wait 1 second after infinite content is injected before trying to lazy load
// the images.
var refreshLazyImage = debounce(lazyImage.refresh, 1000);
// var refreshLazyImage = lazyImage.refresh;

var init = function(opts) {

	setupDom();
	options = getDefaults();
	$.extend(options, dom.grid.data('image-grid-options'), opts);

	console.log('system image-grid options: ', options);

	if (options.isMasonry) {

		masonry.init(dom.grid, {
			isTextHidden: options.isTextHidden
		});

	} else {

		// We only need to apply focal points to non-masonry layouts b/c
		// masonry layouts show the full image.
		focalPoint.init({
			target: '.image-grid [data-focal-point]'
		});

	}

	lazyImage.init({
		target: '.image-grid__image',
		offset: options.lazyOffset,
		debounce: true,
		debounceWait: options.lazyDebounceWait,
		customScroller: options.customScroller,
		customScrollerEvent: options.customScrollerEvent
	});

	// If we have native scrolling, use ScrollWatch to implement infinite
	// scrolling and watching so we can hide images when they go offscreen
	// to increase performance and fix FF memory leaks.
	if (!isCustomScroller()) {

		setupInitialState();

		swInstance = new ScrollWatch({
			watch: '.image-grid__image-wrapper',
			watchOnce: false,
			watchOffset: options.watchOffset,
			scrollThrottle: options.watchThrottle,
			resizeThrottle: options.watchThrottle,
			inViewClass: 'image-grid__image-wrapper--is-viewable',
			infiniteScroll: true,
			infiniteOffset: options.infiniteOffset,
			onInfiniteYInView: function(/*data*/) {

				console.log('infinite y in view...');

				var lastItemIndex = options.isMasonry ? masonry.getLastItemIndex() : state.lastItemIndex;
				// Offset for cf base 1 indexes.
				var itemStartIndex = lastItemIndex + 2;
				var url = options.infiniteUrl.indexOf('?') === -1 ? options.infiniteUrl + '?' : options.infiniteUrl;

				url += '&itemStartIndex=' + itemStartIndex;

				// If we haven't requested this batch yet and there are more batches
				// available.
				console.log('infinite url cache...', state.infiniteRequestCache);
				if (!state.infiniteRequestCache[url] && options.totalItems > itemStartIndex) {

					console.log('system image-grid making infinite request: ' + url);

					state.infiniteRequestCache[url] = true;

					// The batch url may or may not be in our internal cache. If not,
					// an ajax request will be made, otherwise the data will immediately be
					// returned from the cache.
					// We need to defer this request until after the current stack
					// completes because we are referencing swInstance in the callback.
					// If we have this url in our cache factory, no ajax request will
					// be made and the callback will fire immediately, which would
					// be a problem on ScrollWatch instantiation, since swInstance will
					// not be defined until the instantiation completes.
					defer(function(infiniteUrl) {

						getMoreItems(infiniteUrl, function(response) {

							console.log('system image-grid infinite request done!');

							var html = JSON.parse(response).galleryHtml;

							dom.grid.append(html);

							if (options.isMasonry) {

								// Sort the newly injected cells.
								masonry.addItems();

							} else {

								state.lastItemIndex = parseInt(dom.grid.find('.image-grid__item').last().attr('data-image-grid-index'), 10);

							}

							refreshLazyImage();

							swInstance.refresh();

						});

					}, url);

				}

			}
		});

	}

};

var destroy = function() {

	dom = null;
	options = null;
	state = null;
	lazyImage.destroy();
	focalPoint.destroy();
	masonry.destroy();

	if (swInstance) {

		swInstance.destroy();
		swInstance = null;

	}

};

module.exports = {

	init: init,
	destroy: destroy

};
